@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'flag-icon-css/css/flag-icons.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark {
  color-scheme: dark;
}

.dark body {
  background-color: #111827;
  color: #fff;
}

/* Language dropdown styles */
.flag-icon {
  display: inline-block;
  width: 1.5em;
  line-height: 1em;
}

/* Dark mode transitions */
.dark-transition {
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}
